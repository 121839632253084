/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import {Field, Fields, reduxForm, formValueSelector} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';
import {withRouter, type ContextRouter} from 'react-router-dom';

import {colors} from 'shells/colors';
import {TinyIntroLayout} from 'shells/layout';
import {GraphLady, BalloonMan} from 'shells/illustrations';
import {nonNull} from 'shells/form/validation';

import {analyticsClient} from '../../../analytics-client';
import type {NutshellState} from '../../../store';
import {checkProvisionStatus, signupAnalyticsRecord} from '../utils';
import {TINY_INTRO_REDUX_FORM} from '../constants';
import {setProvisionStatus, setProvisionErrorMessage} from '../tiny-intro-actions';
import {
    getIsAccountProvisioned,
    getAccountProvisionedResponse,
    getAccountProvisionedErrorMessage,
} from '../tiny-intro-selector';
import {
    TinyIntroCompanyNameLogo,
    TinyIntroRadioButtons,
    TinyIntroSelectPicker,
    validateForAnyValue,
} from '../fields';

import {TinyIntroductionWrapper} from './tiny-introduction-wrapper';
import {PhoneAndTextFields} from './phone-and-text-fields';
import {QuestionSlide} from './slides/question-slide';
import {FinalSlide} from './slides/final-slide';

import './tiny-introduction.css';

const companyNameSelector = formValueSelector(TINY_INTRO_REDUX_FORM);
const teamValueSelector = formValueSelector(TINY_INTRO_REDUX_FORM);
const crmValueSelector = formValueSelector(TINY_INTRO_REDUX_FORM);

function mapStateToProps(state: NutshellState): StateProps {
    return {
        isProvisioningComplete: getIsAccountProvisioned(state),
        accountProvisionedResponse: getAccountProvisionedResponse(state),
        accountProvisionedErrorMessage: getAccountProvisionedErrorMessage(state),
        companyName: companyNameSelector(state, 'companyName'),
        companyLogoUrl: companyNameSelector(state, 'companyLogoUrl'),
        teamSize: teamValueSelector(state, 'teamSize'),
        crmExperience: crmValueSelector(state, 'crmExperience'),
        textMessageOptIn: crmValueSelector(state, 'textMessageOptIn'),
    };
}

const mapDispatchToProps = function (dispatch): DispatchProps {
    return {
        setAccountProvisionedResponse: (provisionResponse) => {
            dispatch(setProvisionStatus(provisionResponse));
        },
        setAccountProvisionedErrorMessage: (message) => {
            dispatch(setProvisionErrorMessage(message));
        },
    };
};

const PROVISIONED_MESSAGE = 'Instance provisioned';

const SLIDE_KEY_WELCOME = 'company';
const SLIDE_KEY_CRM_EXPERIENCE = 'crm-experience';
const SLIDE_KEY_COMPLETE = 'complete';

export type SlideQuestion = {
    fields?: React.Node[],
    title: string,
    subtitle: string,
    infoMessage?: string,
    nextText?: string,
    footer?: React.Node,
    shouldPreventNext?: boolean,
    onNextCallback?: (Object) => Promise<*>,
};

export type FormValues = {
    companyName: string,
    teamSize: string,
    workPhone: string,
    crmExperience: string,
    textMessageOptIn: boolean,
};

type SlideImage = {
    component: React.Node,
    backgroundColor: string,
};

type Slide = {
    nextSlide: ?string,
    image?: SlideImage,
    component: React.Node,
    progress: number,
};

type StateProps = {|
    isProvisioningComplete: boolean,
    accountProvisionedResponse: Object,
    accountProvisionedErrorMessage: ?string,
    companyName?: string,
    companyLogoUrl?: string,
    teamSize?: string,
    crmExperience?: string,
    textMessageOptIn?: boolean,
|};

type DispatchProps = {|
    setAccountProvisionedResponse: (provisionResponse: {
        status: boolean,
        emailSha1: string,
        instanceId: string,
    }) => void,
    setAccountProvisionedErrorMessage: (message: string) => void,
|};

type OwnProps = {|
    slideId: string,
    email: string,
    domainIsPinnable: boolean,
    signupToken: string,
    onComplete: (FormValues, instanceId: string, emailSha1: string) => void,
    initialValues: Object,
|};

type RoutedProps = $Exact<{...OwnProps, ...ContextRouter}>;

type ConnectedProps = $Exact<{...RoutedProps, ...DispatchProps, ...StateProps}>;

type Props = $Exact<{...$Exact<FormProps>, ...RoutedProps, ...DispatchProps, ...StateProps}>;

class TinyIntroductionComponent extends React.PureComponent<Props> {
    componentDidMount() {
        checkProvisionStatus(
            {username: this.props.email, signupToken: this.props.signupToken},
            (response) => {
                if (response && response.status === PROVISIONED_MESSAGE) {
                    // save instance id and sha1 email for use in nut analytics
                    this.props.setAccountProvisionedResponse({
                        ...response,
                        status: true,
                    });
                }
            },
            () => {
                const message = 'This is awkward… something went wrong creating your account.';
                this.props.setAccountProvisionedErrorMessage(message);
            }
        );
        this.handleSlideViewed();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.slideId !== this.props.slideId) {
            this.handleSlideViewed();
        }
    }

    render() {
        const currentSlide = this.getCurrentSlide();

        return (
            <TinyIntroLayout
                key={this.props.slideId}
                leftComponent={
                    currentSlide.image && currentSlide.image.component
                        ? currentSlide.image.component
                        : undefined
                }
                backgroundColor={
                    currentSlide.image && currentSlide.image.backgroundColor
                        ? currentSlide.image.backgroundColor
                        : undefined
                }
            >
                <TinyIntroductionWrapper
                    onBack={
                        // First and last slide don't have back buttons
                        this.props.slideId === SLIDE_KEY_WELCOME ||
                        this.props.slideId === SLIDE_KEY_COMPLETE
                            ? undefined
                            : this.handleBack
                    }
                    progress={currentSlide.progress}
                >
                    {currentSlide.component}
                </TinyIntroductionWrapper>
            </TinyIntroLayout>
        );
    }

    getCurrentSlide = () => {
        return this.allSlides()[this.props.slideId];
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    handleNext = () => {
        const currentSlide = this.getCurrentSlide();

        if (currentSlide.nextSlide) {
            this.props.history.push(
                `/welcome/${currentSlide.nextSlide}`,
                this.props.location.state
            );
        }
    };

    handleSlideViewed = () => {
        signupAnalyticsRecord({
            username: this.props.email,
            signupToken: this.props.signupToken,
            event: analyticsClient.EVENTS.TRACK_TINYINTRO_SLIDE_VIEWED,
            properties: {slide: this.props.slideId},
        });
    };

    allSlides = (): {[string]: Slide} => {
        return {
            [SLIDE_KEY_WELCOME]: {
                nextSlide: SLIDE_KEY_CRM_EXPERIENCE,
                progress: 0.3,
                image: {
                    component: (
                        <div styleName='slide-image'>
                            <GraphLady />
                        </div>
                    ),
                    backgroundColor: colors.tinyIntroTeal,
                },
                component: (
                    <QuestionSlide
                        slideQuestion={{
                            shouldPreventNext: !this.props.teamSize,
                            fields: [
                                <Fields
                                    key='companyName'
                                    names={['companyName', 'companyLogoUrl']}
                                    title={
                                        this.props.companyLogoUrl
                                            ? 'Your company'
                                            : 'What is your company name?'
                                    }
                                    validate={{
                                        companyName: validateForAnyValue(
                                            'Please enter a company name'
                                        ),
                                    }}
                                    isRequired={true}
                                    component={TinyIntroCompanyNameLogo}
                                />,
                                <PhoneAndTextFields
                                    key='phoneText'
                                    companyLogoUrl={this.props.companyLogoUrl}
                                />,
                                <Field
                                    key='teamSize'
                                    name='teamSize'
                                    title='How many people are on your team?'
                                    type='text'
                                    isRequired={true}
                                    validate={[nonNull]}
                                    component={TinyIntroSelectPicker}
                                    minHeight={48}
                                    styles={{
                                        control: (base) => ({...base, fontSize: 18}),
                                        menu: (base) => ({
                                            ...base,
                                            fontSize: 18,
                                        }),
                                        option: (base) => ({
                                            ...base,
                                            padding: '16px 8px',
                                        }),
                                    }}
                                    // See AuthController for value mapping
                                    options={[
                                        {
                                            value: 'no',
                                            label: 'Just me (for now!)',
                                        },
                                        {
                                            value: 'two',
                                            label: '2-3 team members',
                                        },
                                        {
                                            value: 'small',
                                            label: '4-10 team members',
                                        },
                                        {
                                            value: 'large',
                                            label: '11-20 team members',
                                        },
                                        {
                                            value: 'xl',
                                            label: '21-50 team members',
                                        },
                                        {
                                            value: '2xl',
                                            label: '50-100 team members',
                                        },
                                        {
                                            value: '3xl',
                                            label: 'More than 100',
                                        },
                                    ]}
                                />,
                            ],
                            title: 'Welcome to Nutshell!',
                            subtitle: 'Let’s get you set up—this will only take a few minutes.',
                        }}
                        onSubmit={this.handleNext}
                    />
                ),
            },
            [SLIDE_KEY_CRM_EXPERIENCE]: {
                nextSlide: SLIDE_KEY_COMPLETE,
                progress: 0.6,
                image: {
                    component: (
                        <div styleName='slide-image--small'>
                            <BalloonMan height='100%' />
                        </div>
                    ),
                    backgroundColor: colors.tinyIntroTeal,
                },
                component: (
                    <QuestionSlide
                        slideQuestion={{
                            shouldPreventNext: !this.props.crmExperience,
                            subtitle:
                                'Great! And what’s your experience using customer relationship management (CRM) tools?',
                            fields: [
                                <Field
                                    key='crmExperience'
                                    name='crmExperience'
                                    type='text'
                                    isRequired={true}
                                    component={TinyIntroRadioButtons}
                                    // See AuthController for value mapping
                                    options={[
                                        {
                                            value: 'none',
                                            title: 'Just starting out with a CRM',
                                        },
                                        {
                                            value: 'little',
                                            title: 'Have some CRM experience',
                                        },
                                        {
                                            value: 'experienced',
                                            title: 'Seasoned CRM user, familiar with other tools',
                                        },
                                    ]}
                                />,
                            ],
                        }}
                        onSubmit={this.handleNext}
                    />
                ),
            },
            [SLIDE_KEY_COMPLETE]: {
                nextSlide: null,
                progress: 1,
                image: {
                    component: this.props.accountProvisionedErrorMessage ? (
                        <img
                            styleName='slide-image'
                            alt=''
                            src='/include/images/lead/entity-permissions.svg'
                        />
                    ) : (
                        <img styleName='slide-image' alt='' src='/include/images/auth/finish.svg' />
                    ),
                    backgroundColor: colors.tinyIntroOffWhite,
                },
                component: (
                    <FinalSlide
                        onSubmit={(formValues) => {
                            this.props.onComplete(
                                formValues,
                                this.props.accountProvisionedResponse.instanceId,
                                this.props.accountProvisionedResponse.emailSha1
                            );
                        }}
                        accountProvisionedErrorMessage={this.props.accountProvisionedErrorMessage}
                        isProvisioningComplete={this.props.isProvisioningComplete}
                    />
                ),
            },
        };
    };
}

const connector = connect<ConnectedProps, RoutedProps, _, _, _, _>(
    mapStateToProps,
    mapDispatchToProps
);

export const TinyIntroduction = withRouter<OwnProps>(
    connector(reduxForm({form: TINY_INTRO_REDUX_FORM})(TinyIntroductionComponent))
);
