/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {Link as RouterLink} from 'react-router-dom';
import type {FormProps} from 'redux-form/lib/types.js.flow';

import {Header, Subheader} from 'shells/typography';
import {LoadingIcon} from 'shells/icon';
import {Button} from 'shells/button';
import {Link} from 'shells/link';
import {ErrorMessage} from 'shells/form';
import {Line} from 'shells/divider';
import {Stack} from 'shells/layout';

import {MFASidebarController} from '../../setup/mfa/mfa-sidebar-controller';

import {
    AUTHENTICATOR_MFA_REQUIRED_VALIDATION_MESSAGE,
    PHONE_MFA_REQUIRED_VALIDATION_MESSAGE,
    MFA_NOT_CONFIGURED_BUT_ENFORCED_MESSAGE,
    LOGIN_REDUX_FORM,
} from '../constants';
import {TinyIntroTextField} from '../fields/tiny-intro-text-field';
import {TinyIntroCheckbox} from '../fields/tiny-intro-checkbox';

import {GoogleOAuthButton} from '../google-oauth/google-oauth-button';
import {MicrosoftOauthButton} from '../microsoft-oauth/microsoft-oauth-button';
import {MFACodeForm} from './mfa-code-form';

import './login-form.css';

const emailSelector = formValueSelector(LOGIN_REDUX_FORM);

function mapStateToProps(state): StateProps {
    return {
        emailValue: emailSelector(state, 'username'),
    };
}

type OwnProps = {|
    onGoogleSubmit: (string) => Promise<*>,
    onMicrosoftSubmit: (?string) => Promise<*>,
    onMicrosoftIdentityFailure: (?Error) => void,
    // If Google rejects the identity of the user,
    // i.e. before we even try to authenticate with Nutshell
    onGoogleIdentityFailure: () => void,
    googleErrorMessage: ?string,
    microsoftErrorMessage: ?string,
    microsoftOauthUrl: ?string,
    initialValues: {
        username?: string,
        invalidCsrf?: boolean,
    },
|};

type StateProps = {|
    emailValue?: string,
|};

type Props = {...OwnProps, ...StateProps, ...$Exact<FormProps>};

type State = {
    showGoogleLoginSpinner: boolean,
    submittingMicrosoftIdentity: boolean,
    attemptedSignupWithExistingAccount: boolean,
    hasInvalidToken: boolean,
    isSessionTimeout: boolean,
    isEnteringMFACode: boolean,
    isConfiguringMFA: boolean,
    mfaType?: string,
};

function validate(values: Object) {
    const errors = {};

    if (!values.username || !values.username.trim()) {
        errors.username = 'Please enter an email address';
    }

    if (!values.password || !values.password.trim()) {
        errors.password = 'Please enter your password';
    }

    return errors;
}

class LoginFormComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showGoogleLoginSpinner: false,
            submittingMicrosoftIdentity: false,
            // We can make this more robust if we need to–currently treating URL
            // email param as a signup attempt with an existing account
            attemptedSignupWithExistingAccount: Boolean(props.initialValues.username),
            // Encompasses both CSRF and expired password reset token,
            // we show the same generic error message
            hasInvalidToken: Boolean(props.initialValues.invalidToken),
            isSessionTimeout: Boolean(props.initialValues.sessionTimeout),
            isEnteringMFACode: false,
            isConfiguringMFA: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            nextProps.error === AUTHENTICATOR_MFA_REQUIRED_VALIDATION_MESSAGE &&
            !this.props.error
        ) {
            this.setState({isEnteringMFACode: true, mfaType: 'authenticator'});
        } else if (nextProps.error === PHONE_MFA_REQUIRED_VALIDATION_MESSAGE && !this.props.error) {
            this.setState({isEnteringMFACode: true, mfaType: 'phone'});
        } else if (
            nextProps.error === MFA_NOT_CONFIGURED_BUT_ENFORCED_MESSAGE &&
            !this.props.error
        ) {
            this.setState({isConfiguringMFA: true});
        }
    }

    render() {
        let alternateMessage;
        if (this.state.attemptedSignupWithExistingAccount) {
            alternateMessage = (
                <Subheader>
                    It looks like you’ve already got an account with that email address! Please log
                    in to continue.
                </Subheader>
            );
        } else if (this.state.hasInvalidToken) {
            alternateMessage = (
                <ErrorMessage>
                    There was a problem authenticating and securing your request.
                </ErrorMessage>
            );
        } else if (this.state.isSessionTimeout) {
            alternateMessage = (
                <ErrorMessage>
                    Your session timed out due to your company’s timeout policy
                </ErrorMessage>
            );
        }

        return (
            <React.Fragment>
                {this.state.isConfiguringMFA ? <MFASidebarController /> : undefined}
                {this.state.isEnteringMFACode ? (
                    <MFACodeForm
                        onSubmit={this.props.handleSubmit}
                        onBack={() => {
                            location.reload();
                        }}
                        error={this.props.error}
                        valid={this.props.valid}
                        submitting={this.props.submitting}
                        submitFailed={this.props.submitFailed}
                        mfaType={this.state.mfaType}
                    />
                ) : undefined}
                {!this.state.isEnteringMFACode && !this.state.isConfiguringMFA ? (
                    <>
                        <form onSubmit={this.props.handleSubmit}>
                            {alternateMessage || (
                                <React.Fragment>
                                    <Header>Welcome back!</Header>
                                    <Subheader>Please log in to continue.</Subheader>
                                </React.Fragment>
                            )}
                            <div styleName='fields'>
                                <Stack spacing={24}>
                                    <div>
                                        <GoogleOAuthButton
                                            variant='login'
                                            onGoogleOAuthSuccess={(token: string) => {
                                                this.setState({showGoogleLoginSpinner: true});

                                                this.props.onGoogleSubmit(token).then(() => {
                                                    this.setState({showGoogleLoginSpinner: false});
                                                });
                                            }}
                                            onGoogleOAuthFailure={
                                                this.props.onGoogleIdentityFailure
                                            }
                                            showLoadingSpinner={this.state.showGoogleLoginSpinner}
                                        />
                                    </div>
                                    {this.props.googleErrorMessage ? (
                                        <div styleName='error-message'>
                                            <ErrorMessage>
                                                {this.props.googleErrorMessage}
                                            </ErrorMessage>
                                        </div>
                                    ) : undefined}
                                    <MicrosoftOauthButton
                                        variant='login'
                                        oauthUrl={this.props.microsoftOauthUrl}
                                        onAuthSuccess={(token) => {
                                            this.setState({submittingMicrosoftIdentity: true});
                                            this.props.onMicrosoftSubmit(token).then(() => {
                                                this.setState({submittingMicrosoftIdentity: false});
                                            });
                                        }}
                                        onAuthFailure={(error) => {
                                            this.setState({submittingMicrosoftIdentity: false});
                                            this.props.onMicrosoftIdentityFailure(error);
                                        }}
                                        isSubmittingAuthResult={
                                            this.state.submittingMicrosoftIdentity
                                        }
                                    />
                                    {this.props.microsoftErrorMessage ? (
                                        <div styleName='error-message'>
                                            <ErrorMessage>
                                                {this.props.microsoftErrorMessage}
                                            </ErrorMessage>
                                        </div>
                                    ) : undefined}
                                    <Line text='or' />
                                </Stack>
                            </div>
                            <div styleName='fields'>
                                <Field
                                    key='email'
                                    name='username'
                                    placeholder='john.appleseed@nutshell.com'
                                    title='Email'
                                    type='email'
                                    autoFocus={true}
                                    autocomplete={true}
                                    component={TinyIntroTextField}
                                />
                                <Field
                                    key='password'
                                    name='password'
                                    placeholder='··········'
                                    title='Password'
                                    type='password'
                                    component={TinyIntroTextField}
                                />
                            </div>
                            <div styleName='button submit'>
                                <Button
                                    // Turn this button blue when all the fields are valid
                                    variant={this.props.valid ? 'primary' : 'secondary'}
                                    type='submit'
                                    size='xlarge'
                                    disabled={this.props.submitting}
                                    style={{fontSize: 16, height: 51}} /* Matches textfield */
                                    isFullWidth={true}
                                >
                                    <div styleName='button-contents-container'>
                                        {this.props.submitting ? 'Logging in…' : 'Log in'}
                                        {this.props.submitting ? (
                                            <div styleName='loading-icon-container'>
                                                <LoadingIcon fill='#fff' size={29} />
                                            </div>
                                        ) : undefined}
                                    </div>
                                </Button>
                            </div>
                            {this.props.submitFailed && this.props.error ? (
                                <div styleName='error-message'>
                                    <ErrorMessage>{this.props.error}</ErrorMessage>
                                </div>
                            ) : null}
                            <div styleName='label'>
                                <Field
                                    key='remember_me'
                                    name='remember_me'
                                    title='Stay logged in?'
                                    component={TinyIntroCheckbox}
                                />
                                <Link
                                    as={RouterLink}
                                    to={{
                                        pathname: '/auth/forgot-password',
                                        search: this.props.emailValue
                                            ? `?email=${this.props.emailValue}`
                                            : undefined,
                                    }}
                                    variant='primary'
                                >
                                    Forgot password?
                                </Link>
                            </div>
                        </form>

                        <div styleName='login-container'>
                            Don’t have an account?{' '}
                            <Link
                                as={RouterLink}
                                variant='primary'
                                size='normal'
                                to={{
                                    pathname: '/signup',
                                    search: this.props.emailValue
                                        ? `?email=${this.props.emailValue}`
                                        : undefined,
                                }}
                            >
                                Sign up now
                            </Link>
                        </div>
                    </>
                ) : undefined}
            </React.Fragment>
        );
    }
}

const connector = connect<Props, {|...OwnProps, ...$Exact<FormProps>|}, _, _, _, _>(
    mapStateToProps
);

export const LoginForm = reduxForm({
    form: LOGIN_REDUX_FORM,
    validate,
})(connector(LoginFormComponent));
