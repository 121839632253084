/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import {type ContextRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {headTitle} from 'shells/document';

import type {NutshellState} from '../../../store';
import {getUserEmail, getSignupToken} from '../tiny-intro-selector';
import {setEmail, setSignupToken} from '../tiny-intro-actions';
import {isMobileSignup, login} from '../utils';
import {TinyIntroduction} from './tiny-introduction';
import type {FormValues} from './tiny-introduction';

type DispatchProps = {|
    setEmail: (email: string) => void,
    setSignupToken: (token: string) => void,
|};

type StateProps = {|
    userEmail: ?string,
    userSignupToken: ?string,
|};

type Props = {...ContextRouter, ...DispatchProps, ...StateProps};

function mapStateToProps(state: NutshellState): StateProps {
    return {
        userEmail: getUserEmail(state),
        userSignupToken: getSignupToken(state),
    };
}

const mapDispatchToProps = function (dispatch): DispatchProps {
    return {
        setEmail: (email) => {
            dispatch(setEmail(email));
        },
        setSignupToken: (token) => {
            dispatch(setSignupToken(token));
        },
    };
};

class TinyIntroductionPageComponent extends React.Component<Props> {
    render() {
        const state = this.props.location.state;
        const slideId = this.props.match.params.slideId;

        if (state && state.signupToken && state.email) {
            this.props.setEmail(state.email);
            this.props.setSignupToken(state.signupToken);
        }

        const email = this.getUserEmailFromReduxOrLocation();
        const signupToken = this.getUserSignupTokenFromReduxOrLocation();

        if (email && signupToken && state && slideId) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>{headTitle('Welcome')}</title>
                    </Helmet>
                    <TinyIntroduction
                        email={email}
                        signupToken={signupToken}
                        slideId={slideId}
                        domainIsPinnable={state.domainIsPinnable}
                        onComplete={this.handleComplete}
                        initialValues={{
                            companyName: state.companyName,
                            companyLogoUrl: state.logoUrl,
                        }}
                    />
                </React.Fragment>
            );
        }

        // If we don't have everything we need, load the signup page instead.
        window.location = '/signup';

        return null;
    }

    handleComplete = (formValues: FormValues, instanceId: string, emailSha1: string) => {
        const locationState = this.props.location ? this.props.location.state : undefined;
        if (locationState) {
            // trigger analytics event (which go on to google tag manager (gtm), segment, etc)
            Analytics.trialSignupComplete(locationState.email, formValues, instanceId, emailSha1);

            if (isMobileSignup()) {
                login(locationState.email, locationState.signupToken);
            } else {
                fetch('/auth/signup-login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 7 /* User_Token::TYPE_INITIAL_LOGIN */,
                        token: locationState.signupToken,
                        username: locationState.email,
                        signupFormValues: formValues,
                    }),
                })
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.success === true) {
                            // wait 500 ms to give GTM + Segment time to send the event. yolo.
                            setTimeout(() => {
                                window.location = '/dashboard?product_tour_id=387970';
                            }, 500);
                        }
                    });
            }
        }
    };

    getUserEmailFromReduxOrLocation = (): ?string => {
        if (this.props.location && this.props.location.state && this.props.location.state.email) {
            return this.props.location.state.email;
        }

        if (this.props.userEmail) {
            return this.props.userEmail;
        }

        return undefined;
    };

    getUserSignupTokenFromReduxOrLocation = (): ?string => {
        if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.signupToken
        ) {
            return this.props.location.state.signupToken;
        }

        if (this.props.userSignupToken) {
            return this.props.userSignupToken;
        }

        return undefined;
    };
}

const connector = connect<Props, ContextRouter, _, _, _, _>(mapStateToProps, mapDispatchToProps);
export const TinyIntroductionPage = connector(TinyIntroductionPageComponent);
