/* @flow */
import * as React from 'react';
import {ErrorMessage} from 'shells/form';
import {BACKSPACE, LEFT, RIGHT, TAB} from '../utils/keys';
import {LoadingIcon} from '../icon';
import {colors} from '../colors';

import './mfa-code.css';

type Props = {
    inputCount: number,
    onVerifyMfaCode: (string) => Promise<*>,
};

export function MfaCode(props: Props) {
    const inputRefs = React.useRef([]);
    const inputBoxes = [];

    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(
        'This code doesn’t look quite right — try retyping it'
    );

    const handleKeyDown = (event) => {
        setIsError(false);
        if (isBackspaceEvent(event)) {
            if (isEmptyInput(event)) {
                deleteInput(event, true); // deletePreviousInput
                focusInput(event, false, true); // focusPreviousInput
            } else {
                // Do nothing, onChange will delete the input
            }
        } else if (isRightArrowEvent(event)) {
            focusInput(event, true); // focusNextInput
        } else if (isLeftArrowEvent(event)) {
            focusInput(event, true, true); // focusPreviousInput
        } else if (isShiftTabEvent(event)) {
            event.preventDefault();
            focusInput(event, true, true); // focusPreviousInput
        } else if (isTabEvent(event)) {
            event.preventDefault();
            focusInput(event); // focusNextInput
        } else if (!isNumeric(event) && event.keyCode !== 91 && event.keyCode !== 86) {
            // If not numerical input, or control or v (for paste), ignore it.
            event.preventDefault();
        } else if (!isEmptyInput(event)) {
            // If the selected box already has a character, overwrite it
            deleteInput(event); // deleteCurrentInput
        }
    };

    const handleChange = (event) => {
        if (!isEmptyInput(event)) {
            focusInput(event); // focusNextInput
        }

        verifyCode();
    };

    const handleClick = (event) => {
        const tabIndex = event.currentTarget.tabIndex;
        const input = inputRefs.current[tabIndex];

        // Timeout ensures that select state sticks
        setTimeout(function () {
            if (input instanceof HTMLInputElement) {
                input.select();
            }
        }, 0);
        if (input instanceof HTMLInputElement) {
            input.placeholder = '';
        }
    };

    /**
     * Override default paste behavior, insert each char into boxes sequentially
     * @param {*} event Paste event
     */
    const handlePaste = (event) => {
        event.preventDefault();

        // Get pasted text
        let pastedText;
        if (window.clipboardData && window.clipboardData.getData) {
            // Legacy support
            pastedText = window.clipboardData.getData('Text');
        } else if (event.clipboardData && event.clipboardData.getData) {
            pastedText = event.clipboardData.getData('text/plain');
        }

        // Remove any spaces from pasted text, Set text in boxes to the text that was pasted
        if (pastedText !== undefined) {
            pastedText = pastedText.split(' ').join('');
            setValue(pastedText);
            focusGivenInput(Math.min(pastedText.length, props.inputCount - 1));
        }

        verifyCode();
    };

    const focusInput = (event, select = false, previous = false) => {
        // Focus the next or previous input, determined by boolean
        const tabIndex = previous
            ? event.currentTarget.tabIndex - 1
            : event.currentTarget.tabIndex + 1;

        focusGivenInput(tabIndex, select);
    };

    const focusGivenInput = (tabIndex, select = false) => {
        const input = inputRefs.current[tabIndex];

        if (input instanceof HTMLInputElement) {
            if (select) {
                // Timeout ensures that select state sticks
                setTimeout(function () {
                    input.select();
                }, 0);
            }

            input.placeholder = '';
            input.focus();
        }
    };

    const handleBlur = (event) => {
        // Restore placeholder when input loses focus
        const tabIndex = event.currentTarget.tabIndex;
        const input = inputRefs.current[tabIndex];

        if (input instanceof HTMLInputElement) {
            input.placeholder = '-';
        }
    };

    const deleteInput = (event, previous = false) => {
        const tabIndex = previous ? event.currentTarget.tabIndex - 1 : event.currentTarget.tabIndex;
        const input = inputRefs.current[tabIndex];

        if (input instanceof HTMLInputElement) {
            input.value = '';
        }
    };

    const isEmptyInput = (event) => {
        const tabIndex = event.currentTarget.tabIndex;
        const input = inputRefs.current[tabIndex];

        if (input instanceof HTMLInputElement) {
            if (input.value === '') {
                return true;
            } else {
                return false;
            }
        }
    };

    const isShiftTabEvent = (event) => {
        if (event.shiftKey) {
            if (event.keyCode === TAB) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    };

    const isNumeric = (event) => {
        return event.keyCode >= 48 && event.keyCode <= 57;
    };

    const isBackspaceEvent = (event) => {
        return event.keyCode === BACKSPACE;
    };

    const isLeftArrowEvent = (event) => {
        return event.keyCode === LEFT;
    };

    const isRightArrowEvent = (event) => {
        return event.keyCode === RIGHT;
    };

    const isTabEvent = (event) => {
        return event.keyCode === TAB;
    };

    /**
     * Set the value of text boxes
     * @param {*} value Value to be set
     */
    const setValue = (value) => {
        for (let i = 0; i < props.inputCount; i++) {
            const input = inputRefs.current[i];
            if (input instanceof HTMLInputElement) {
                input.value = value.charAt(i);
            }
        }
    };

    /**
     * Get the value held acrossed the text boxes
     * @returns User entered value
     */
    const getValue = () => {
        let value = '';

        for (let i = 0; i < props.inputCount; i++) {
            const input = inputRefs.current[i];
            if (input instanceof HTMLInputElement) {
                value = value.concat(input.value);
            }
        }

        return value;
    };

    const disableInputs = (disable: boolean) => {
        for (let i = 0; i < props.inputCount; i++) {
            const input = inputRefs.current[i];
            if (input instanceof HTMLInputElement) {
                input.disabled = disable;
            }
        }
    };

    /** Clear and re-enable input, end loading indicator, focus first box */
    const reset = () => {
        setIsLoading(false);
        disableInputs(false);
        setValue('');
        focusGivenInput(0, true);
    };

    const verifyCode = () => {
        const value = getValue();

        if (value.length === props.inputCount) {
            setIsLoading(true);
            disableInputs(true);

            props
                .onVerifyMfaCode(getValue())
                .then(() => {
                    // no-op, handled by parent
                })
                .catch((result) => {
                    setIsError(true);

                    if (result && result.messages.length && result.messages[0]) {
                        setErrorMessage(result.messages[0]);
                    } else {
                        setErrorMessage('Something went wrong');
                    }

                    reset();
                });
        }
    };

    // Generate input box for each digit
    for (let i = 0; i < props.inputCount; i++) {
        inputBoxes.push(
            <input
                key={i}
                ref={(el) => (inputRefs.current[i] = el)}
                id={i}
                autoComplete='off'
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onClick={handleClick}
                onBlur={handleBlur}
                maxLength='1'
                placeholder={'-'}
                tabIndex={i}
                onPaste={handlePaste}
                styleName='tfa-code'
            />
        );
    }

    return (
        <>
            <div styleName='mfa-row'>
                {inputBoxes}
                {isLoading ? (
                    <LoadingIcon styleName='loadingIcon' fill={colors.offWhiteDk} size={40} />
                ) : undefined}
            </div>
            {isError ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
        </>
    );
}
