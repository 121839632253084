/* @flow */

import * as React from 'react';
import {QRCodeIcon, CellPhoneIcon} from 'shells/icon';
import {Stack} from 'shells/layout/stack';
import {TwoLine, TwoLineButtonCard} from 'shells/two-line';
import {getMfa} from '../../../auth/utils';
import type {Method, GetMfaApiResponse} from '../types';

import {TYPE_APP, TYPE_SMS} from '../constants';

import './select-method-screen.css';

type Props = {|
    setSelectedMfaMethod: (Method) => void,
    selectedMfaMethod: Method,
|};

export function SelectMethodScreen(props: Props) {
    const [enabledMfaMethod, setMfaMethod] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const {selectedMfaMethod, setSelectedMfaMethod} = props;

    React.useEffect(() => {
        getMfa().then((result: GetMfaApiResponse) => {
            if (result.result && result.result.type !== null) {
                setMfaMethod(result.result.type);
            }
            setIsLoading(false);
        });
    }, [setSelectedMfaMethod, setIsLoading]);

    if (isLoading) {
        return null;
    }

    return (
        <Stack spacing={16} isHorizontal={false}>
            <TwoLineButtonCard
                onClick={() => setSelectedMfaMethod('sms')}
                isSelected={selectedMfaMethod === 'sms'}
            >
                <TwoLine
                    icon={CellPhoneIcon}
                    iconSize={24}
                    topRow={enabledMfaMethod === TYPE_SMS ? 'New SMS number' : 'SMS'}
                    bottomRow={
                        enabledMfaMethod === TYPE_SMS
                            ? 'Use a different phone number to generate log in codes'
                            : 'We’ll text you a code to enter at log in'
                    }
                    isLargeWithSmallBottomRow={true}
                    shouldCenterSingleLine={true}
                />
            </TwoLineButtonCard>

            <TwoLineButtonCard
                onClick={() => props.setSelectedMfaMethod('app')}
                isSelected={props.selectedMfaMethod === 'app'}
            >
                <TwoLine
                    icon={QRCodeIcon}
                    iconSize={24}
                    topRow={
                        enabledMfaMethod === TYPE_APP
                            ? 'New authenticator app'
                            : 'Authenticator app'
                    }
                    bottomRow={
                        enabledMfaMethod === TYPE_APP
                            ? 'Use a different app to generate log in codes'
                            : 'Use an app to generate a code to enter at log in'
                    }
                    isLargeWithSmallBottomRow={true}
                    shouldCenterSingleLine={true}
                />
            </TwoLineButtonCard>

            {/* If an MFA method is already enabled, show it as a disabled option. */}
            {enabledMfaMethod !== null ? (
                <TwoLineButtonCard
                    onClick={() => props.setSelectedMfaMethod('app')}
                    isSelected={props.selectedMfaMethod === 'app'}
                    isDisabled={true}
                    disabled={true}
                >
                    <TwoLine
                        icon={enabledMfaMethod === TYPE_SMS ? CellPhoneIcon : QRCodeIcon}
                        iconSize={24}
                        topRow={enabledMfaMethod === TYPE_SMS ? 'SMS' : 'Authenticator app'}
                        bottomRow={<span styleName='success'>Current method</span>}
                        isLargeWithSmallBottomRow={true}
                        shouldCenterSingleLine={true}
                    />
                </TwoLineButtonCard>
            ) : undefined}
        </Stack>
    );
}
