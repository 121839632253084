/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, formValueSelector} from 'redux-form';
import {Link as RouterLink} from 'react-router-dom';
import type {FormProps} from 'redux-form/lib/types.js.flow';
import {Header, Subheader} from 'shells/typography';
import {Line} from 'shells/divider';
import {Button} from 'shells/button';
import {LoadingIcon} from 'shells/icon';
import {ErrorMessage} from 'shells/form';
import {Link} from 'shells/link';

import {validateForAnyValue} from '../fields/validate-for-any-value';
import type {EmailSignupFormValues} from '../types';
import {SIGNUP_REDUX_FORM} from '../constants';
import {TinyIntroTextField} from '../fields/tiny-intro-text-field';
import {GoogleOAuthButton} from '../google-oauth/google-oauth-button';
import {isMobileSignup, showLoginForm} from '../utils';
import {MicrosoftOauthButton} from '../microsoft-oauth/microsoft-oauth-button';

import './signup-form.css';

const ERROR_INVALID_EMAIL_MESSAGE = 'invalid_email';

const emailSelector = formValueSelector(SIGNUP_REDUX_FORM);

function mapStateToProps(state): StateProps {
    return {
        emailValue: emailSelector(state, 'email'),
    };
}

const validatePassword = (value: string) => {
    if (!value) return 'Please enter a password';

    return value.trim().length < 6 ? 'Please choose a more complex password' : undefined;
};

type SignUpType = 'email' | 'google';

type OwnProps = {|
    onGoogleSignup: (token: string) => Promise<*>,
    onEmailSignup: (formValues: EmailSignupFormValues) => Promise<*>,
    // If Google rejects the identity of the user,
    // i.e. before we even try to authenticate with Nutshell
    onGoogleIdentityFailure: () => void,
    googleErrorMessage?: string,
    // If the user's email is already attached to an account,
    // we need to modify the UI to tell the user we are logging
    // them in.
    isLoggingInWithGoogle: boolean,
    onMicrosoftSignup: (token: string) => Promise<*>,
    onMicrosoftIdentityFailure: (?Error) => void,
    microsoftErrorMessage?: string,
    isLoggingInWithMicrosoft: boolean,
    microsoftOauthUrl: string,
|};

type StateProps = {|
    emailValue?: string,
|};

type Props = {...OwnProps, ...$Exact<FormProps>, ...StateProps};

type State = {
    showEmailSignupSpinner: boolean,
    showGoogleSignupSpinner: boolean,
    primarySignupType: SignUpType,
    isMicrosoftAuthing: boolean,
};

export class SignupFormComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showEmailSignupSpinner: false,
            showGoogleSignupSpinner: false,
            primarySignupType: props.initialValues.email ? 'email' : 'google',
            isMicrosoftAuthing: false,
        };
    }

    render() {
        return (
            <React.Fragment>
                <form
                    onSubmit={this.props.handleSubmit((values: EmailSignupFormValues) => {
                        // Show loading spinner
                        this.setState({showEmailSignupSpinner: true});

                        return this.props
                            .onEmailSignup(values)
                            .then(() => {
                                // Stop showing loading spinner
                                this.setState({showEmailSignupSpinner: false});
                            })
                            .catch((err) => {
                                // Stop showing loading spinner
                                this.setState({showEmailSignupSpinner: false});

                                if (err.message === ERROR_INVALID_EMAIL_MESSAGE) {
                                    throw new SubmissionError({
                                        email: 'This email doesn’t seem valid.  Please doublecheck it.',
                                    });
                                }

                                throw new SubmissionError({
                                    _error: err.message,
                                });
                            });
                    })}
                >
                    {this.renderHeaderText()}
                    {/* If Google is primary signup type, show the Google button at the top */}
                    {this.state.primarySignupType === 'google' ? (
                        <React.Fragment>
                            {this.renderGoogleButton()}
                            {this.renderErrorMessage(this.props.googleErrorMessage)}
                            {this.renderMicrosoftButton()}
                            {this.renderErrorMessage(this.props.microsoftErrorMessage)}
                            {this.renderOrSeparator()}
                        </React.Fragment>
                    ) : undefined}
                    {this.renderEmailFields()}
                    {this.renderEmailButton()}
                    {/* If Email is primary signup type, show the Google button at the bottom */}
                    {this.state.primarySignupType === 'email' ? (
                        <React.Fragment>
                            {this.renderOrSeparator()}
                            {this.renderGoogleButton()}
                            {this.renderMicrosoftButton()}
                            {this.renderErrorMessage(this.props.microsoftErrorMessage)}
                            {this.renderErrorMessage(this.props.googleErrorMessage)}
                        </React.Fragment>
                    ) : undefined}
                    {this.renderErrorMessage(this.props.error)}
                </form>
                <div styleName='login-container'>
                    Already have an account?{' '}
                    <Link
                        as={RouterLink}
                        variant='primary'
                        size='normal'
                        onClick={this.handleLoginClick}
                        to={
                            !isMobileSignup()
                                ? {
                                      pathname: '/auth',
                                      search: this.props.emailValue
                                          ? `?email=${this.props.emailValue}`
                                          : undefined,
                                  }
                                : undefined
                        }
                    >
                        Log in
                    </Link>
                </div>
            </React.Fragment>
        );
    }

    handleLoginClick = (e: Event) => {
        if (!isMobileSignup()) {
            return;
        }

        e.stopPropagation();

        showLoginForm();
    };

    renderHeaderText() {
        return this.props.initialValues.firstAndLastName ? (
            <React.Fragment>
                <Header>
                    Welcome, {getFirstNameFromFullName(this.props.initialValues.firstAndLastName)}!
                </Header>
                <Subheader>Let’s finish setting up your Nutshell trial…</Subheader>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Header>Start your free 14-day Nutshell trial</Header>
                <Subheader>No credit card required, cancel anytime</Subheader>
            </React.Fragment>
        );
    }

    renderEmailFields() {
        return (
            <div styleName={this.state.primarySignupType === 'email' ? 'space-above' : ''}>
                <Field
                    key='firstAndLastName' // Weird name, I know, but its so auto-fill doesnt fill it
                    name='firstAndLastName'
                    placeholder='John Appleseed'
                    title='Full name'
                    type='text'
                    // This seems like a backwards boolean, but if the
                    // primarySignupType _is_ email, that means we've
                    // already gathered name and email, so we want
                    // to prefocus password. But, if we don't have the
                    // user's name, we _do_ need to focus it.
                    autoFocus={
                        this.state.primarySignupType !== 'email' ||
                        !this.props.initialValues.firstAndLastName
                    }
                    validate={validateForAnyValue('Please enter a name')}
                    component={TinyIntroTextField}
                    disabled={
                        this.state.showGoogleSignupSpinner || this.props.isLoggingInWithGoogle
                    }
                />
                <Field
                    key='email'
                    name='email'
                    required={true}
                    placeholder='john.appleseed@nutshell.com'
                    title='Email'
                    type='email'
                    component={TinyIntroTextField}
                    disabled={
                        this.state.showGoogleSignupSpinner || this.props.isLoggingInWithGoogle
                    }
                />
                <Field
                    key='password'
                    name='password'
                    placeholder='··········'
                    title='Password'
                    type='password'
                    // This seems like a backwards boolean, but if the
                    // primarySignupType _is_ email, that means we've
                    // already gathered name and email, so we want
                    // to prefocus password. But, if we don't have the
                    // user's name, we _do_ need to focus it.
                    autoFocus={
                        this.state.primarySignupType === 'email' &&
                        this.props.initialValues.firstAndLastName
                    }
                    validate={validatePassword}
                    component={TinyIntroTextField}
                    disabled={
                        this.state.showGoogleSignupSpinner || this.props.isLoggingInWithGoogle
                    }
                />
            </div>
        );
    }

    renderEmailButton() {
        return (
            <div styleName={this.state.showEmailSignupSpinner ? 'button-loading' : 'button'}>
                <Button
                    type='submit'
                    size='xlarge'
                    style={{fontSize: 16, height: 51}} /* Matches textfield */
                    isFullWidth={true}
                    variant='primary'
                    disabled={
                        this.state.showEmailSignupSpinner ||
                        this.state.showGoogleSignupSpinner ||
                        this.props.isLoggingInWithGoogle
                    }
                >
                    <div styleName='button-contents-container'>
                        {this.state.showEmailSignupSpinner ? 'Signing up…' : 'Sign up'}
                        {this.state.showEmailSignupSpinner ? (
                            <div styleName='loading-icon-container'>
                                <LoadingIcon fill='#fff' size={26} />
                            </div>
                        ) : undefined}
                    </div>
                </Button>
            </div>
        );
    }

    renderGoogleButton() {
        return (
            <div styleName='space-above'>
                <GoogleOAuthButton
                    variant={this.props.isLoggingInWithGoogle ? 'login' : 'signup'}
                    onGoogleOAuthSuccess={(token: string) => {
                        this.setState({showGoogleSignupSpinner: true});

                        this.props.onGoogleSignup(token).then(() => {
                            this.setState({showGoogleSignupSpinner: false});
                        });
                    }}
                    onGoogleOAuthFailure={this.props.onGoogleIdentityFailure}
                    showLoadingSpinner={
                        this.state.showGoogleSignupSpinner || this.props.isLoggingInWithGoogle
                    }
                />
                {this.props.isLoggingInWithGoogle ? (
                    <div styleName='below-button-message'>
                        You already have a Nutshell account. Logging you in now…
                    </div>
                ) : undefined}
            </div>
        );
    }

    renderMicrosoftButton() {
        return (
            <div styleName='space-above'>
                <MicrosoftOauthButton
                    variant={this.props.isLoggingInWithMicrosoft ? 'login' : 'signup'}
                    oauthUrl={this.props.microsoftOauthUrl}
                    onAuthSuccess={(token: string) => {
                        this.setState({isMicrosoftAuthing: true});
                        this.props.onMicrosoftSignup(token).then(() => {
                            this.setState({isMicrosoftAuthing: false});
                        });
                    }}
                    onAuthFailure={(error) => {
                        this.setState({isMicrosoftAuthing: false});
                        this.props.onMicrosoftIdentityFailure(error);
                    }}
                    isSubmittingAuthResult={
                        this.props.isLoggingInWithMicrosoft || this.state.isMicrosoftAuthing
                    }
                />
                {this.props.isLoggingInWithGoogle ? (
                    <div styleName='below-button-message'>
                        You already have a Nutshell account. Logging you in now…
                    </div>
                ) : undefined}
            </div>
        );
    }

    renderOrSeparator() {
        return (
            <div key='seperator' styleName='seperator'>
                <Line text='or' />
            </div>
        );
    }

    renderErrorMessage = (errorMessage?: string) => {
        if (!errorMessage) {
            return undefined;
        }

        return (
            <div styleName='below-button-message'>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </div>
        );
    };
}

// Naive way to just get the first string from a full name
function getFirstNameFromFullName(fullName: string) {
    return fullName.split(' ')[0];
}

const connector = connect<Props, {|...OwnProps, ...$Exact<FormProps>|}, _, _, _, _>(
    mapStateToProps
);

export const SignupForm = reduxForm({
    form: SIGNUP_REDUX_FORM,
    destroyOnUnmount: true,
})(connector(SignupFormComponent));
